<template>
	<div class="main-card">
		<div class="main-card__poster">
			<img
				v-lazy="content.img"
				:width="config.size.width"
				:height="config.size.height"
				:alt="content.name"
			/>
		</div>
		<h3 class="main-card__hdng">{{ content.name }}</h3>
		<span class="main-card__text">
			{{ text }}
		</span>
	</div>
</template>

<script>
import declension from '@/utils/declension'

const _config = {
	small: {
		class: 'small',
		size: {
			width: '285',
			height: '236'
		}
	},
	big: {
		class: 'big',
		size: {
			width: '386',
			height: '347'
		}
	}
}

export default {
	props: {
		type: {
			type: String,
			default: 'big' // maybe: big/small
		},
		content: {
      type: Object,
      default: () => ({
        name: '',
        key: '',
        number: 0,
        img: ''
      })
    }
	},
	computed: {
		config() {
			return _config[this.type]
		},
		text() {
      return `${this.content.number} доступных мест`
			// return `${this.content.number}
      //  ${declension(
			// 		['доступное', 'доступные', 'доступных'],
			// 		this.content.number
			// 	)}
      //  ${declension(['место', 'места', 'мест'], this.content.number)}`
		}
	}
}
</script>

<style lang="sass">
.main-card
  overflow: hidden
  margin-right: 5px
  &__poster
    display: flex
    align-items: center
    justify-content: center
    overflow: hidden
    border-radius: 10px
    margin-bottom: 20px
    img
      object-fit: cover

  &__hdng
    margin-bottom: 5px
    font-weight: 600
    font-size: 18px
    line-height: 21px
    color: #2A3043

  &__text
    font-size: 12px
    color: #AFAFAF
</style>
